import React, { useEffect, useState } from "react";
import Select from "react-select"; // Pour le select avec recherche
import DatePicker from "react-datepicker"; // Pour le champ de date
import "react-datepicker/dist/react-datepicker.css"; // Importer les styles de react-datepicker
import { ProgressSpinner } from "primereact/progressspinner";

import { Button } from "primereact/button";

import Nav from "./component/nav";
import StepOne from "./component/stepOne/StepOne";
import { StepTwo } from "./component/stepTwo/StepTwo";
import StepThree from "./component/stepThree/StepThree";
import { StepFour } from "./component/stepFour/StepFour";
import StepFive from "./component/stepFive/StepFive";
import { StepSix } from "./component/stepSix/StepSix";
import StepSeven from "./component/stepSeven/StepSeven";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import StepEight from "./component/stepEight/StepEight";
import CryptoJS from 'crypto-js';


const App = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false); // État pour suivre le chargement
  const [visible, setVisible] = useState(false);
  const [sucees, setSucees] = useState("");

  const [error, setError] = useState(); // État pour suivre les erreurs

  const [formType, setFormType] = useState("");
  const [pieceNumber, setPieceNumber] = useState("");
  const [pieceType, setPieceType] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [expireDate, setExpireDate] = useState(null);
  const [file, setFile] = useState(null);
  const [foreignPieceNumber, setForeignPieceNumber] = useState("");
  const [foreignDeliveredDate, setForeignDeliveredDate] = useState(null);
  const [foreignVille, setForeignVille] = useState("");
  const [foreignValidityPeriod, setForeignValidityPeriod] = useState("");

  // Step 2 fields
  const [civility, setCivility] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [maidenName, setMaidenName] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  const [birthCity, setBirthCity] = useState(null);

  const [maritalStatus, setMaritalStatus] = useState("");
  const [legalCapacity, setLegalCapacity] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [motherName, setMotherName] = useState("");
  const [spouseName, setSpouseName] = useState("");

  // Step 3 fields
  const [nationality, setNationality] = useState("");
  const [secondNationality, setSecondNationality] = useState("");
  const [status, setStatus] = useState("");
  const [residenceCountry, setResidenceCountry] = useState("");
  const [residenceCity, setResidenceCity] = useState(null);
  const [residenceNeighborhood, setResidenceNeighborhood] = useState("");
  const [lotNumber, setLotNumber] = useState("");
  const [houseOwner, setHouseOwner] = useState("");
  const [description, setDescription] = useState("");

  // Step 4 fields
  const [mobile1, setMobile1] = useState("");
  const [mobile2, setMobile2] = useState("");
  const [officePhone, setOfficePhone] = useState("");
  const [email1, setEmail1] = useState("");
  const [email2, setEmail2] = useState("");

  //steps 5 fields
  const [profession, setProfession] = useState("");
  const [country, setCountry] = useState("");

  const [professionalAddress, setProfessionalAddress] = useState("");
  const [professionalContact, setProfessionalContact] = useState("");

  // Champs supplémentaires conditionnels pour entrepreneur
  const [rccm, setRccm] = useState("");
  const [businessCategory, setBusinessCategory] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [registrationLocation, setRegistrationLocation] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [activitySector, setActivitySector] = useState("");

  const [ifu, setIfu] = useState("");
  const [employeeCount, setEmployeeCount] = useState("");
  const [legalRepresentative, setLegalRepresentative] = useState("");

  // Champs supplémentaires conditionnels
  const [employer, setEmployer] = useState("");
  const [employerAddress, setEmployerAddress] = useState("");
  const [employerCountry, setEmployerCountry] = useState("");
  const [employerCity, setEmployerCity] = useState("");
  const [employerMobile, setEmployerMobile] = useState("");

  const [employerWeb, setEmployerWeb] = useState("");
  const [employerEMail, setEmployerEMail] = useState("");
  const [employerContrat, setEmployerContrat] = useState(null);

  // Steps 6 fields
  const [bankName1, setBankName1] = useState("");
  const [bankLocation1, setBankLocation1] = useState("");
  const [accountTitle1, setAccountTitle1] = useState("");
  const [accountType1, setAccountType1] = useState("");
  const [accountNumber1, setAccountNumber1] = useState("");

  //  Steps 7 fields
  const [contactName, setContactName] = useState("");
  const [contactRelation, setContactRelation] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const [contactProfession, setContactProfession] = useState("");
  const [contactEmployer, setContactEmployer] = useState("");
  const [contactWorkAddress, setContactWorkAddress] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");

  // Step 9 fields
  const [checked, setChecked] = useState(false);

  // Fonction pour convertir les objets Date en chaînes de caractères
  const formatDate = (date) => {
    return date ? date.toLocaleDateString() : "N/A";
  };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleNext = () => setStep((prev) => prev + 1);
  const handlePrevious = () => setStep((prev) => prev - 1);
// Fonction pour vérifier si une chaîne est un email valide
function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
  const isStepValid = () => {
    switch (step) {
      case 1:
        if (formType?.code === "local") {
          return pieceNumber && pieceType && selectedCity && expireDate && file;
        } else if (formType?.code === "foreign") {
          return (
            foreignPieceNumber &&
            // foreignDeliveredDate &&
            foreignValidityPeriod &&
            file
          );
        }
        return false;
      case 2:
        // Validation pour l'étape 2
        return (
          civility &&
          lastName &&
          firstName &&
          birthDate &&
          birthCity &&
          maritalStatus &&
          legalCapacity  &&
           (civility.name !== "Mme" || maidenName) 
    
        );
      case 3:
        // Validation pour l'étape 3
        return (
          nationality &&
          status &&
          residenceCountry &&
          residenceCity &&
          residenceNeighborhood &&
          lotNumber &&
          houseOwner
        );
      case 4:
        // Validation pour l'étape 4
        return (mobile1 || mobile2) && (isValidEmail(email1 )|| isValidEmail(email2));
      case 5:
        // Validation pour l'étape 5
        if (!profession || !country) {
          return false;
        }

        // Rendre certains champs obligatoires en fonction de la profession sélectionnée
        if (profession?.name === "Entrepreneur-Individuel") {
          return (
            rccm &&
            businessCategory &&
            businessName &&
            registrationDate &&
            registrationLocation &&
            businessAddress &&
            businessPhone &&
           isValidEmail( businessEmail) &&
            activitySector &&
            ifu &&
            employeeCount &&
            legalRepresentative
          );
        }

        if (
          ["Fonctionnaire-Public", "Fonctionnaire-Privé", "Ouvrier"].includes(
            profession?.name
          )
        ) {
          return (
            employer &&
            employerAddress &&
            employerCountry &&
            employerCity &&
            employerMobile &&

           isValidEmail( employerEMail )&&
            employerContrat
          );
        }

        return true;
      case 6:
        // Validation pour l'étape 6
        return (
          bankName1 &&
          bankLocation1 &&
          accountTitle1 &&
          accountType1 &&
          accountNumber1
        );
      case 7:
        // Validation pour l'étape 7
        return (
          contactName &&
          contactRelation &&
          contactAddress &&
          contactProfession &&
          contactEmployer &&
          contactWorkAddress &&
          contactPhone &&
         isValidEmail(contactEmail)
        );

      case 8:
        // Validation pour l'étape 8
        if (step === 8) {
          return true;
        }

      default:
        return false;
    }
  };

  const isNextDisabled = !isStepValid();

  // Fonction de chiffrement
  const encryptData = (data) => {
    // Utilisez process.env.SECRET_KEY pour une clé de chiffrement sécurisée
    const secretKey = process.env.SECRET_KEY || '0123456789abcdef0123456789abcdef'; // Assurez-vous que la clé a 32 octets
  
    // Chiffrement avec AES
    const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(secretKey), {
      iv: CryptoJS.enc.Utf8.parse('1234567890123456'), // Utilisez un IV de 16 octets (doit être le même lors du déchiffrement)
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  
    // Retournez le texte chiffré au format Base64
    return encrypted.toString();
  };

  const handleSubmit = async () => {
    setLoading(true);
    // Construction de l'objet info_html avec les données des différents steps
    const info_html = `
    <div>
      <h2 style="margin-top:30px;">Informations Générales</h2>
      <div style="margin-bottom:10px"><b>Type de Formulaire:</b> <span>${formType?.name}</span></div>
      <div style="margin-bottom:10px"><b>Numéro de Pièce:</b> <span>${pieceNumber ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Type de Pièce:</b> <span>${pieceType?.name ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Ville de Délivrance:</b> <span>${selectedCity ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Date d'Expiration:</b> <span>${formatDate(
        expireDate
      )}</span></div>
      <div style="margin-bottom:10px"><b>Numéro de Pièce Étrangère:</b> <span>${
        foreignPieceNumber ?? ""
      }</span></div>
      <div style="margin-bottom:10px"><b>Date de Délivrance Étrangère:</b> <span>${formatDate(
        foreignDeliveredDate
      )}</span></div>
      <div style="margin-bottom:10px"><b>Ville Étrangère:</b> <span>${foreignVille ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Période de Validité Étrangère:</b> <span>${
        foreignValidityPeriod ?? ""
      }</span></div>
      
      <h2 style="margin-top:30px;">Étape 2: Informations Personnelles</h2>
      <div style="margin-bottom:10px"><b>Civilité:</b> <span>${civility?.name}</span></div>
      <div style="margin-bottom:10px"><b>Nom:</b> <span>${lastName ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Prénom:</b> <span>${firstName ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Nom de Jeune Fille:</b> <span>${maidenName ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Date de Naissance:</b> <span>${formatDate(birthDate)}</span></div>
      <div style="margin-bottom:10px"><b>Ville de Naissance:</b> <span>${birthCity ?? ""}</span></div>
   
      <div style="margin-bottom:10px"><b>État Civil:</b> <span>${maritalStatus?.name}</span></div>
      <div style="margin-bottom:10px"><b>Capacité Juridique:</b> <span>${legalCapacity?.name}</span></div>

      <h2 style="margin-top:30px;">Étape 3: Informations de Résidence</h2>
      <div style="margin-bottom:10px"><b>Nationalité:</b> <span>${nationality ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Deuxième Nationalité:</b> <span>${
        secondNationality ?? ""
      }</span></div>
      <div style="margin-bottom:10px"><b>Statut:</b> <span>${status?.name}</span></div>
      <div style="margin-bottom:10px"><b>Pays de Résidence:</b> <span>${
        residenceCountry ?? ""
      }</span></div>
      <div style="margin-bottom:10px"><b>Ville de Résidence:</b> <span>${residenceCity ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Quartier de Résidence:</b> <span>${
        residenceNeighborhood ?? ""
      }</span></div>
      <div style="margin-bottom:10px"><b>Numéro de Lot:</b> <span>${lotNumber ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Propriétaire de la Maison:</b> <span>${
        houseOwner ?? ""
      }</span></div>
      <div style="margin-bottom:10px"><b>Description:</b> <span>${description ?? ""}</span></div>

      <h2 style="margin-top:30px;">Étape 4: Contacts</h2>
      <div style="margin-bottom:10px"><b>Mobile 1:</b> <span>${mobile1 ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Mobile 2:</b> <span>${mobile2 ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Téléphone de Bureau:</b> <span>${officePhone ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Email 1:</b> <span>${email1 ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Email 2:</b> <span>${email2 ?? ""}</span></div>

      <h2 style="margin-top:30px;">Étape 5: Profession et Activité</h2>
      <div style="margin-bottom:10px"><b>Profession:</b> <span>${profession ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Pays:</b> <span>${country ?? ""}</span></div>
     
    
      <div style="margin-bottom:10px"><b>Adresse Professionnelle:</b> <span>${
        professionalAddress ?? ""
      }</span></div>
      <div style="margin-bottom:10px"><b>Contact Professionnel:</b> <span>${
        professionalContact ?? ""
      }</span></div>
      ${
        profession === "entrepreneur"
          ? `
      <h3>Informations d'Entrepreneur</h3>
      <div style="margin-bottom:10px"><b>RCCM:</b> <span>${rccm ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Catégorie de l'Entreprise:</b> <span>${
        businessCategory.name ?? ""
      }</span></div>
      <div style="margin-bottom:10px"><b>Nom de l'Entreprise:</b> <span>${businessName ?? ""}</span></div>
      <div style="margin-bottom:10px"><b>Date d'Enregistrement:</b> <span>${formatDate(
        registrationDate
      )}</span></div>
      <div style="margin-bottom:10px"><b>Lieu d'Enregistrement:</b> <span>${registrationLocation}</span></div>
      <div style="margin-bottom:10px"><b>Adresse de l'Entreprise:</b> <span>${businessAddress}</span></div>
      <div style="margin-bottom:10px"><b>Téléphone de l'Entreprise:</b> <span>${businessPhone}</span></div>
      <div style="margin-bottom:10px"><b>Email de l'Entreprise:</b> <span>${businessEmail}</span></div>
      <div style="margin-bottom:10px"><b>Site Web de l'Entreprise:</b> <span>${businessWebsite}</span></div>
      <div style="margin-bottom:10px"><b>Secteur d'Activité:</b> <span>${activitySector}</span></div>
   >

      <div style="margin-bottom:10px"><b>IFU:</b> <span>${ifu}</span></div>
      <div style="margin-bottom:10px"><b>Nombre d'Employés:</b> <span>${employeeCount}</span></div>
      <div style="margin-bottom:10px"><b>Représentant Légal:</b> <span>${legalRepresentative}</span></div>`
          : ""
      }
      ${
        ["fonctionnaire-public", "fonctionnaire-prive", "ouvrier"].includes(
          profession
        )
          ? `
      <h3>Informations d'Employeur</h3>
      <div style="margin-bottom:10px"><b>Employeur:</b> <span>${employer}</span></div>
      <div style="margin-bottom:10px"><b>Adresse de l'Employeur:</b> <span>${employerAddress}</span></div>
      <div style="margin-bottom:10px"><b>Pays de l'Employeur:</b> <span>${employerCountry}</span></div>
      <div style="margin-bottom:10px"><b>Ville de l'Employeur:</b> <span>${employerCity}</span></div>
      <div style="margin-bottom:10px"><b>Mobile de l'Employeur:</b> <span>${employerMobile}</span></div>

      <div style="margin-bottom:10px"><b>Email de l'Employeur:</b> <span>${employerEMail}</span></div>
      <div style="margin-bottom:10px"><b>Contrat de l'Employeur:</b> <span>${employerContrat}</span></div>`
          : ""
      }

      <h2 style="margin-top:30px;">Étape 6: Informations Bancaires</h2>
      <div style="margin-bottom:10px"><b>Banque 1:</b> <span>${bankName1}</span></div>
      <div style="margin-bottom:10px"><b>Emplacement Banque 1:</b> <span>${bankLocation1}</span></div>
      <div style="margin-bottom:10px"><b>Titre du Compte 1:</b> <span>${accountTitle1}</span></div>
      <div style="margin-bottom:10px"><b>Type de Compte 1:</b> <span>${accountType1}</span></div>
      <div style="margin-bottom:10px"><b>Numéro de Compte 1:</b> <span>${accountNumber1}</span></div>

      <h2 style="margin-top:30px;">Étape 7: Contact en Cas d'Urgence</h2>
      <div style="margin-bottom:10px"><b>Nom du Contact:</b> <span>${contactName}</span></div>
      <div style="margin-bottom:10px"><b>Relation:</b> <span>${contactRelation}</span></div>
      <div style="margin-bottom:10px"><b>Adresse:</b> <span>${contactAddress}</span></div>
      <div style="margin-bottom:10px"><b>Profession:</b> <span>${contactProfession}</span></div>
      <div style="margin-bottom:10px"><b>Employeur:</b> <span>${contactEmployer}</span></div>
      <div style="margin-bottom:10px"><b>Adresse de Travail:</b> <span>${contactWorkAddress}</span></div>
      <div style="margin-bottom:10px"><b>Téléphone:</b> <span>${contactPhone}</span></div>
      <div style="margin-bottom:10px"><b>Email:</b> <span>${contactEmail}</span></div>

    
    </div>
  `;

  const encryptedInfoHtml = await encryptData(info_html);


    // Préparation des données à envoyer
    const formData = new FormData();
    formData.append("infosHtml", encryptedInfoHtml);
    formData.append("file", file);
    formData.append(
      "idMerchant",
      new URLSearchParams(window.location.search).get("id_merchant")
    );

    // Envoi des données au serveur
    try {
      const response = await axios.post(
        `https://api.feexpay.me/api/merchant/save-kyc`,
        formData
      );

      if (response.status === 200) {
        setLoading(false);
        setVisible(true);
        setError(false);
      }
    } catch (error) {
      if (error) {
        setLoading(false);
        setVisible(true);
        setError(true);
        //console.log(error);
      }
    }
  };

  return (
    <div className="max-w-lg m-auto p-4 bg-white shadow-lg rounded-lg">
      <Nav />

      <h1 className="text-2xl font-bold mb-4">
        FICHE DE RENSEIGNEMENT CLIENT FEEXPAY(KYC) POUR ACTIVATION DES CARTES
      </h1>

      {step === 1 && (
        <StepOne
          formType={formType}
          setFormType={setFormType}
          pieceType={pieceType}
          pieceNumber={pieceNumber}
          setPieceNumber={setPieceNumber}
          setPieceType={setPieceType}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          expireDate={expireDate}
          setExpireDate={setExpireDate}
          foreignPieceNumber={foreignPieceNumber}
          setForeignPieceNumber={setForeignPieceNumber}
          foreignDeliveredDate={foreignDeliveredDate}
          setForeignDeliveredDate={setForeignDeliveredDate}
          foreignVille={foreignVille}
          setForeignVille={setForeignVille}
          foreignValidityPeriod={foreignValidityPeriod}
          setForeignValidityPeriod={setForeignValidityPeriod}
          handleFileChange={handleFileChange}
          file={file}
        />
      )}
      {step === 2 && (
        <div>
          <StepTwo
            civility={civility}
            setCivility={setCivility}
            lastName={lastName}
            setLastName={setLastName}
            firstName={firstName}
            setFirstName={setFirstName}
            maidenName={maidenName}
            setMaidenName={setMaidenName}
            birthDate={birthDate}
            setBirthDate={setBirthDate}
            birthCity={birthCity}
            setBirthCity={setBirthCity}
      
            maritalStatus={maritalStatus}
            setMaritalStatus={setMaritalStatus}
            legalCapacity={legalCapacity}
            setLegalCapacity={setLegalCapacity}
            fatherName={fatherName}
            setFatherName={setFatherName}
            motherName={motherName}
            setMotherName={setMotherName}
            spouseName={spouseName}
            setSpouseName={setSpouseName}
          />
        </div>
      )}

      {/* https://api.feexpay.me/api/merchant/save-kyc */}
      {step === 3 && (
        <StepThree
          nationality={nationality}
          setNationality={setNationality}
          secondNationality={secondNationality}
          setSecondNationality={setSecondNationality}
          status={status}
          setStatus={setStatus}
          residenceCountry={residenceCountry}
          setResidenceCountry={setResidenceCountry}
          residenceCity={residenceCity}
          setResidenceCity={setResidenceCity}
          residenceNeighborhood={residenceNeighborhood}
          setResidenceNeighborhood={setResidenceNeighborhood}
          lotNumber={lotNumber}
          setLotNumber={setLotNumber}
          houseOwner={houseOwner}
          setHouseOwner={setHouseOwner}
          description={description}
          setDescription={setDescription}
        />
      )}
      {step === 4 && (
        <StepFour
          mobile1={mobile1}
          setMobile1={setMobile1}
          mobile2={mobile2}
          setMobile2={setMobile2}
          officePhone={officePhone}
          setOfficePhone={setOfficePhone}
          email1={email1}
          setEmail1={setEmail1}
          email2={email2}
          setEmail2={setEmail2}
        />
      )}
      {step === 5 && (
        <StepFive
          profession={profession}
          setProfession={setProfession}
          country={country}
          setCountry={setCountry}
          professionalAddress={professionalAddress}
          setProfessionalAddress={setProfessionalAddress}
          professionalContact={professionalContact}
          setProfessionalContact={setProfessionalContact}
          rccm={rccm}
          setRccm={setRccm}
          businessCategory={businessCategory}
          setBusinessCategory={setBusinessCategory}
          businessName={businessName}
          setBusinessName={setBusinessName}
          registrationDate={registrationDate}
          setRegistrationDate={setRegistrationDate}
          registrationLocation={registrationLocation}
          setRegistrationLocation={setRegistrationLocation}
          businessAddress={businessAddress}
          setBusinessAddress={setBusinessAddress}
          businessPhone={businessPhone}
          setBusinessPhone={setBusinessPhone}
          businessEmail={businessEmail}
          setBusinessEmail={setBusinessEmail}
          businessWebsite={businessWebsite}
          setBusinessWebsite={setBusinessWebsite}
          activitySector={activitySector}
          setActivitySector={setActivitySector}
          ifu={ifu}
          setIfu={setIfu}
          employeeCount={employeeCount}
          setEmployeeCount={setEmployeeCount}
          legalRepresentative={legalRepresentative}
          setLegalRepresentative={setLegalRepresentative}
          employer={employer}
          setEmployer={setEmployer}
          employerAddress={employerAddress}
          setEmployerAddress={setEmployerAddress}
          employerCountry={employerCountry}
          setEmployerCountry={setEmployerCountry}
          employerCity={employerCity}
          setEmployerCity={setEmployerCity}
          employerMobile={employerMobile}
          setEmployerMobile={setEmployerMobile}
       
          employerEMail={employerEMail}
          setEmployerEMail={setEmployerEMail}
          employerWeb={employerWeb}
          setEmployerWeb={setEmployerWeb}
          employerContrat={employerContrat}
          setEmployerContrat={setEmployerContrat}
        />
      )}
      {step === 6 && (
        <StepSix
          bankName1={bankName1}
          setBankName1={setBankName1}
          bankLocation1={bankLocation1}
          setBankLocation1={setBankLocation1}
          accountTitle1={accountTitle1}
          setAccountTitle1={setAccountTitle1}
          accountNumber1={accountNumber1}
          setAccountNumber1={setAccountNumber1}
          accountType1={accountType1}
          setAccountType1={setAccountType1}
        />
      )}
      {step === 7 && (
        <StepSeven
          contactName={contactName}
          setContactName={setContactName}
          contactAddress={contactAddress}
          setContactAddress={setContactAddress}
          contactRelation={contactRelation}
          setContactRelation={setContactRelation}
          contactProfession={contactProfession}
          setContactProfession={setContactProfession}
          contactWorkAddress={contactWorkAddress}
          setContactWorkAddress={setContactWorkAddress}
          contactPhone={contactPhone}
          setContactPhone={setContactPhone}
          contactEmail={contactEmail}
          setContactEmail={setContactEmail}
          contactEmployer={contactEmployer}
          setContactEmployer={setContactEmployer}
        />
      )}

      {step === 8 && (
        <StepEight
          civility={civility}
          lastName={lastName}
          firstName={firstName}
          checked={checked}
          setChecked={setChecked}
        />
      )}
      <div className="flex justify-between mt-4">
        {step > 1 && (
          <Button
            label="Précédent"
            onClick={handlePrevious}
            className="colorSecond text-white px-4 py-2 rounded"
          />
        )}
        {step < 8 ? (
          <Button
            label="Suivant"
            onClick={handleNext}
            disabled={isNextDisabled}
            className={`${
              isNextDisabled
                ? "bg-gray-500 cursor-not-allowed"
                : "colorMain hover:bg-orange-700"
            } text-white px-4 py-2 rounded bg-orange-500`}
          />
        ) : loading ? (
          <ProgressSpinner
            style={{ width: "35px", height: "35px" }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        ) : (
          <Button
            label=" Soumettre"
            onClick={handleSubmit}
            className="colorMain hover:bg-orange-700 text-white px-4 py-2 rounded"
            disabled={!checked}
          />
        )}
      </div>
      <Dialog
        header={
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <i
              style={{ color: `${!error ? "green" : "red"}`, fontSize: '2rem' }}
              className={`${!error ? "pi pi-check" : "pi pi-times"}`}
            ></i>
          </div>
        }
        visible={visible}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
        closable={false}
        style={{
          width: "40vw",
          borderColor: error ? "red" : "green",
        }}
        className={error ? "p-dialog-error" : "p-dialog-success"}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <p 
          className="m-0" 
          style={{ textAlign: "center" }} // Centrer le texte
        >
          {error
            ? "Échec de l'envoi du formulaire. Veuillez réessayer."
            : "Vos informations ont été envoyées avec succès. Nous les vérifierons et reviendrons vers vous dans les prochains jours"}
        </p>

        {error ? (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
            <Button
              onClick={() => {
                setVisible(false); // Ferme le modal
              }}
              className="p-button p-component mt-2"
              style={{ padding: "0.5rem 2rem", backgroundColor: "red", color: "white" }}
              label="OK"
            />
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
            <Button
              onClick={() => {
                window.location.reload(); // Rafraîchit la page si error est true
              }}
              className="p-button p-component mt-2"
              style={{ padding: "0.5rem 2rem", backgroundColor: "green", color: "white" }}
              label="OK"
            />
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default App;
